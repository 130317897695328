<template>
  <v-row>
    <v-col cols="12" class="d-flex align-center">
      <h2>{{ $t('daily_login.head') }}</h2>
      <v-divider class="mx-3" vertical />
      <v-breadcrumbs :items="breadcrumbsItems" divider=">>" />
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title>{{ $t('daily_login.create_head_list') }} </v-card-title>

        <v-form ref="form" v-model="formValid" @submit.prevent="submitForm">
          <v-divider class="mx-3" />
          <!-- Title Input -->
          <v-card-actions>
            <v-row class="d-flex flex-column">
              <v-row class="ma-0">
                <v-col cols="12" xs="6" md="6" xl="6">
                  <v-text-field v-model="formData.title" :label="$t('daily_login.header2')" outlined dense
                    disabled></v-text-field>
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-select v-model="formData.month" :items="months" :rules="formRule.month" item-text="name"
                    item-value="value" :label="$t('daily_login.create_month')" outlined dense></v-select>
                </v-col>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-select v-model="formData.year" :items="years" :rules="formRule.year"
                    :label="$t('daily_login.create_year')" outlined dense></v-select>
                </v-col>
              </v-row>
            </v-row>
          </v-card-actions>

          <!-- Bonus type and amount Input -->
          <v-card-actions>
            <v-row class="d-flex flex-column">
              <v-col class="pa-0">
                <v-card-title class="pb-2 px-3">{{ $t('daily_login.create_bonus') }}</v-card-title>
                <v-card-text class="px-3">{{ $t('daily_login.create_bonus_detail') }}</v-card-text>
              </v-col>
              <v-row class="ma-0">
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-select v-model="formData.bonusType" :items="rewardTypes"
                    :rules="[v => !!v || this.$t('daily_login.create_reward_type2')]"
                    :label="$t('daily_login.create_reward_type')" outlined dense></v-select>
                </v-col>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="formData.bonusAmount" :rules="formRule.bonusAmount"
                    :label="$t('daily_login.create_reward_amount')" type="number" min="0" outlined dense></v-text-field>
                </v-col>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="formData.multiplyTurnOver" :rules="formRule.multiplyTurnOver"
                    :label="$t('daily_login.create_turnover')" type="number" min="0" outlined dense></v-text-field>
                </v-col>
              </v-row>
            </v-row>
          </v-card-actions>

          <!-- Daily List -->
          <v-card-actions v-if="formData.month && formData.year" class="d-flex flex-column pa-2">
            <v-col class="pa-0">
              <v-card-title class="pb-2 px-3">{{ $t('daily_login.daily_head') }}</v-card-title>
              <v-card-text class="px-3">{{ $t('daily_login.daily_head_detail') }}</v-card-text>
            </v-col>

            <v-row v-for="(day, index) of days" :key="index" class="pa-2" style="width: 100%">
              <v-row class="ma-0">
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-row>
                    <v-col cols="4" xs="4" md="4" xl="4">
                      <p class="mt-2">{{ `Day ${index + 1}` }}</p>
                    </v-col>
                    <v-col cols="8" xs="8" md="8" xl="8">
                      <v-select v-model="day.type" :items="rewardTypes" :rules="formRule.type"
                        :label="$t('daily_login.create_reward_type')" outlined dense></v-select>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="day.amount" :rules="formRule.amount"
                    :label="$t('daily_login.create_reward_amount')" type="number" min="0" outlined dense></v-text-field>
                </v-col>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="day.multiplyTurnOver" :rules="formRule.multiplyTurnOver"
                    :label="$t('daily_login.create_turnover')" type="number" min="0" outlined dense></v-text-field>
                </v-col>
                <v-col cols="3" class="pa-0">
                  <v-btn v-if="index === 0 && formData.month && formData.year" @click="useAll" color="warning">{{
                    $t('daily_login.use_all') }}</v-btn>
                </v-col>
              </v-row>
            </v-row>
          </v-card-actions>

          <v-card-actions>
            <v-spacer />
            <v-btn color="warning" @click="$router.push({ name: 'daily-login' })">
              <v-icon>mdi-arrow-left</v-icon>
              <span class="px-3">{{ $t('back') }}</span>
            </v-btn>
            <v-btn color="success" class="px-3" @click="submitForm" :loading="loading">
              <v-icon small>mdi-square-edit-outline</v-icon>
              <span color="primary" class="px-3">{{ $t('daily_login.create') }}</span></v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import StaffLimitAccessPage from '@/components/staff/StaffLimitAccessPage.vue'
import store from '@/store'
import moment from 'moment'

export default {
  components: {
    StaffLimitAccessPage,
  },
  data() {
    return {
      loading: false,
      formValid: true,
      formRule: {
        month: [value => !!value || this.$t('daily_login.alert1'), value => this.validateMonthYear('month', value)],
        year: [value => !!value || this.$t('daily_login.alert2'), value => this.validateMonthYear('year', value)],
        // bonusAmount: [
        //   value => !!value || this.$t('daily_login.alert3'),
        //   value => /^\d+$/.test(value) || this.$t('daily_login.alert4'),
        // ],
        bonusAmount: [value => (value !== '' && value !== null && value !== undefined) || this.$t('daily_login.alert3')],
        multiplyTurnOver: [value => (value !== '' && value !== null && value !== undefined) || this.$t('daily_login.alert5')],
        type: [value => !!value || this.$t('daily_login.alert6')],
        amount: [value => (value !== '' && value !== null && value !== undefined) || this.$t('daily_login.alert3')],
      },
      formData: {
        title: '',
        month: null,
        year: null,
        bonusType: 'point',
        bonusAmount: 1000,
        multiplyTurnOver: 0,
        maxDay: null,
        days: [],
      },
      months: [
        { value: 1, name: this.$t('month1') },
        { value: 2, name: this.$t('month2') },
        { value: 3, name: this.$t('month3') },
        { value: 4, name: this.$t('month4') },
        { value: 5, name: this.$t('month5') },
        { value: 6, name: this.$t('month6') },
        { value: 7, name: this.$t('month7') },
        { value: 8, name: this.$t('month8') },
        { value: 9, name: this.$t('month9') },
        { value: 10, name: this.$t('month10') },
        { value: 11, name: this.$t('month11') },
        { value: 12, name: this.$t('month12') },
      ],
      years: [],
      rewardTypes: ['point', 'coin'],

      breadcrumbsItems: [
        {
          text: this.$t('daily_login.head'),
          disabled: false,
          href: '/dailyLogin',
        },
        {
          text: this.$t('daily_login.head2'),
          disabled: true,
          href: '',
        },
      ],

      acceptItems: [
        {
          text: this.$t('yes'),
          value: 'yes',
        },
        {
          text: this.$t('not'),
          value: 'no',
        },
      ],
      roles: [],
    }
  },
  async created() {
    this.addLogPage()
    try {
      this.roles = await this.$store.dispatch('getRoleData')
    } catch (e) { }

    const currentMonth = new Date().getMonth() + 1
    const currentYear = new Date().getFullYear()
    this.formData.title = `ประจำเดือน ${this.getMonthName(currentMonth)} ${currentYear}`

    for (let i = 0; i < 10; i++) {
      this.years.push(currentYear + i)
    }
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await axios.get('https://api.ipify.org?format=json').then(res =>
        (data = {
          ip: res.data.ip,
          name: 'Daily Login',
          url: window.location.href,
          detail: 'สร้าง Daily Login',
          admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
        }),
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    linkTo(name) {
      const id = this.$route.params
      this.$router.push({ name: name, params: { id } })
    },
    useAll() {
      let firstDayData = this.formData.days[0]
      let daysLength = this.formData.days.length
      let updatedDays = []
      for (let i = 0; i < daysLength; i++) {
        updatedDays.push({
          type: firstDayData.type,
          amount: firstDayData.amount,
          multiplyTurnOver: firstDayData.multiplyTurnOver,
        })
      }

      this.$set(this.formData, 'days', updatedDays)
    },
    submitForm() {
      if (this.$refs.form.validate()) {
        this.$swal
          .fire({
            icon: 'warning',
            title: this.$t('alert'),
            text: this.$t('daily_login.alert7'),
            showCancelButton: true,
            confirmButtonText: this.$t('confirm'),
            cancelButtonText: this.$t('cancel'),
          })
          .then(async action => {
            if (action.isConfirmed) {
              await this.addDailyLoginData()
            }
          })
      }
    },
    async addDailyLoginData() {
      // format to int
      this.formData.multiplyTurnOver = parseInt(this.formData.multiplyTurnOver)
      for (let i = 0; i < this.formData.days.length; i++) {
        this.formData.days[i].amount = parseInt(this.formData.days[i].amount)
        this.formData.days[i].multiplyTurnOver = parseInt(this.formData.days[i].multiplyTurnOver)
      }

      this.loading = true
      try {
        let res = await this.$store.dispatch('addDailyLogin', this.formData)
        if (res.status === 'success') {
          this.$swal.fire(`${this.$t('daily_login.create_success1')} ${this.formData.title} ${this.$t('daily_login.create_success2')}`, '', 'success').then(action => {
            this.$router.push({ name: 'daily-login' })
          })
        } else if (res.code === 400) {
          this.$swal.fire(
            `${this.$t('daily_login.create_fail1_1')} ${this.formData.title} ${this.$t('daily_login.create_fail1_2')}`,
            '',
            'error',
          )
        } else {
          this.$swal.fire(this.$t('daily_login.create_fail2'), '', 'error')
        }
        // this.$refs.form.reset()
      } catch (e) {
        if (
          !e.message.includes('Unexpected token') ||
          !e?.includes('Unexpected token') ||
          !e.message.includes('Unexpected identifier')
        ) {
          this.$swal.fire({
            icon: 'error',
            title: e,
          })
        }
      }

      this.loading = false
    },
    getMonthName(monthValue) {
      const month = this.months.find(m => m.value === monthValue)
      return month ? month.name : ''
    },
    getDaysInMonth(month, year) {
      return new Date(year, month, 0).getDate()
    },
    validateMonthYear(type, value) {
      const selectedYear = this.formData.year
      const selectedMonth = this.formData.month

      const currentDate = moment(new Date()).format('YYYY-MM')
      const formattedString = moment(`${selectedYear}-${selectedMonth}`, 'YYYY-M').format('YYYY-MM')

      if (selectedMonth && selectedYear) {
        if (moment(formattedString).isAfter(currentDate)) {
          return true
        } else {
          returnthis.$t('daily_login.alert8')
        }
      } else {
        return true
      }
    },
    setDailyList() {
      const daysInMonth = new Date(this.formData.year, this.formData.month, 0).getDate()
      const currentMonth = new Date(this.formData.year, this.formData.month, 0).getMonth() + 1
      const currentYear = new Date(this.formData.year, this.formData.month, 0).getFullYear()

      let resArr = []
      for (let i = 0; i < daysInMonth; i++) {
        resArr.push({ type: null, amount: null })
      }

      this.$set(this.formData, 'title', `ประจำเดือน ${this.getMonthName(currentMonth)} ${currentYear}`)
      this.$set(this.formData, 'maxDay', daysInMonth)
      this.$set(this.formData, 'days', resArr)
    },
  },
  computed: {
    days() {
      if (this.formData.days.length === 0 && this.formData.month !== null && this.formData.year !== null) {
        this.setDailyList()
      } else {
        return this.formData.days
      }
    },
  },
  watch: {
    'formData.month': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setDailyList()
      }
    },
    'formData.year': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setDailyList()
      }
    },
  },
}
</script>
